import * as React from "react";
import { graphql } from "gatsby";

import Breadcrumbs from "@components/Breadcrumbs";
import Seo from "@components/Seo";

import SortingGallery from "@page_components/gallery/SortingGallery";

function GalleryPageEN({ data }) {
  const gallery = {
    fullImages: data?.fullImages,
    thumbnails: data?.thumbnails,
  };
  const { seo } = data?.seo_data?.nodes[0];
  const breadcrumbs_data = [{ name: "Gallery", href: `/gallery` }];

  return (
    <>
      <Seo title={seo.title} description={seo.metaDesc} />
      <div className="gallery-page">
        <div className="container">
          <Breadcrumbs data={breadcrumbs_data} />

          <main>
            <h1> Gallery </h1>
            <SortingGallery gallery_data={gallery} context="Wszystkie" />
          </main>
        </div>
      </div>
    </>
  );
}

export default GalleryPageEN;

export const query = graphql`
  {
    thumbnails: allWpImage {
      nodes {
        pageGallery {
          gallery {
            sourceUrl
          }
        }
      }
    }
    fullImages: allWpImage {
      nodes {
        pageGallery {
          gallery {
            sourceUrl
          }
        }
      }
    }
    seo_data: allWpPage(filter: { title: { eq: "Gallery" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
      }
    }
  }
`;
